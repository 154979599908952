import { RouteComponentProps } from '@reach/router'
import { Form } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useCallback, useEffect, useReducer, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  Answer,
  DeleteAnswerResponseHandlers,
  DeleteFileResponseHandlers,
  DownloadFileAnswerOptions,
  DownloadFileResponseHandlers,
  Feature,
  FetchStructureResponseHandlers,
  FetchSubsectionResponseHandlers,
  FileAnswer,
  SaveAnswerResponseHandlers,
  SaveFileAnswerResponseHandlers,
  SaveFileResponseOptions,
  SideBySideTest,
  defaultMaxFileBytes,
  deleteTestAnswer,
  deleteTestFileAnswer,
  downloadTestFileAnswer,
  fetchAnswers,
  fetchProjectStructure,
  fetchProjectSubsection,
  fetchSideBySideTest,
  saveTestAnswer,
  saveTestFileAnswer
} from '../../../../requests'
import { routes } from '../../../../routes'
import { getLanguage } from '../../../../utils'
import { DatacLoading, DatacMessage } from '../../../common'
import { useSideBySideProjectDetailsStore } from '../SideBySideProjectDetailsStore'
import {
  TestForm,
  TestFormAction,
  TestFormContext,
  parseTestFormAnswers,
  testFormInitialState,
  testFormStateReducer
} from './TestForm'

interface TestFormWrapperProps {
  test?: SideBySideTest
  children?: React.ReactNode
}

const TestFormWrapper: React.FC<TestFormWrapperProps> = ({ test, children }) => {
  const { project } = useSideBySideProjectDetailsStore()
  const intl = useScopedIntl('')
  const [testFormState, testFormDispatch] = useReducer(testFormStateReducer, testFormInitialState)
  const [formInstance] = Form.useForm()
  const studyId: string = null
  const testId = test.id

  const updateRecord = useCallback(
    (shouldUpdateSubsection = true) => {
      fetchSideBySideTest(
        { testId, projectId: project.id },
        {
          onSuccess: record => testFormDispatch({ type: TestFormAction.SET_RECORD, record }),
          onRequestError: code => DatacMessage.genericError(intl, code)
        }
      )

      if (shouldUpdateSubsection) {
        updateSubsection()
      }
    },
    [testId, testFormState.subsectionId]
  )

  const updateSubsection = useCallback(() => {
    fetchAnswers(
      {
        studyId,
        projectId: project.id,
        recordId: testId,
        feature: Feature.SideBySide,
        subsectionId: testFormState.subsectionId,
        language: getLanguage()
      },
      {
        onSuccess: answers => {
          const parsedAnswers = parseTestFormAnswers(answers)
          testFormDispatch({
            type: TestFormAction.SET_ANSWERED_QUESTION_IDS,
            answeredQuestions: new Set(parsedAnswers.answered)
          })

          formInstance.setFieldsValue(parsedAnswers.values)
        },
        onRequestError: () => {
          formInstance.setFieldsValue({})
          DatacMessage.error(
            intl('studies.fulfillment.subsection.error.fetch_answers.title'),
            intl('studies.fulfillment.subsection.error.fetch_answers.body')
          )
        }
      }
    )
  }, [testId, testFormState.subsectionId])

  const fetchStructure = (responseHandlers?: FetchStructureResponseHandlers) => {
    fetchProjectStructure({ projectId: project.id, studyId }, responseHandlers)
  }

  const saveAnswer = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options: { questionId: string; answer: any; productId: string },
      responseHandlers?: SaveAnswerResponseHandlers<Answer>
    ) => {
      saveTestAnswer(
        {
          testId,
          projectId: project.id,
          productId: options.productId,
          questionId: options.questionId,
          answer: options.answer,
          language: getLanguage()
        },
        responseHandlers
      )
    },
    [testId]
  )

  const deleteAnswer = useCallback(
    (options: { questionId: string; productId: string }, responseHandlers?: DeleteAnswerResponseHandlers) => {
      deleteTestAnswer(
        {
          testId,
          projectId: project.id,
          productId: options.productId,
          questionId: options.questionId
        },
        responseHandlers
      )
    },
    [testId]
  )

  const deleteFile = useCallback(
    (options: { fileId: string }, responseHandlers?: DeleteFileResponseHandlers) => {
      deleteTestFileAnswer({ fileId: options.fileId }, responseHandlers, { testId, projectId: project.id })
    },
    [testId]
  )

  const saveFile = useCallback(
    (options: SaveFileResponseOptions, responseHandlers?: SaveFileAnswerResponseHandlers<FileAnswer>) => {
      saveTestFileAnswer(
        {
          testId,
          projectId: project.id,
          productId: options.productId,
          questionId: options.questionId,
          fileId: options.fileId
        },
        responseHandlers
      )
    },
    [testId]
  )

  const downloadFile = useCallback(
    (options: DownloadFileAnswerOptions, responseHandlers?: DownloadFileResponseHandlers) => {
      downloadTestFileAnswer(options, responseHandlers)
    },
    [testId]
  )

  const fetchSubsection = useCallback(
    (
      { sectionId, subsectionId }: { sectionId: string; subsectionId: string },
      responseHandlers?: FetchSubsectionResponseHandlers
    ) => {
      return fetchProjectSubsection({ sectionId, subsectionId, projectId: project.id, studyId }, responseHandlers)
    },
    [testId]
  )

  const navigateToSubsection = (sectionId: string, subsectionId: string) => {
    testFormDispatch({
      type: TestFormAction.SET_SECTION_AND_SUBSECTION_IDS,
      sectionId,
      subsectionId
    })
    navigate(routes.sideBySideProjectTest(project.id, testId, sectionId, subsectionId), {
      replace: true
    })
  }

  return (
    <Form form={formInstance} name="test-form">
      <TestFormContext.Provider
        value={{
          ...testFormState,
          testFormDispatch,
          formInstance,
          updateRecord,
          updateSubsection,
          fetchStructure,
          fetchSubsection,
          isEditingAvailable: true,
          navigateToSubsection,
          saveAnswer,
          deleteAnswer,
          deleteFile,
          saveFile,
          downloadFile,
          maxFileBytes: defaultMaxFileBytes
        }}
      >
        {children}
      </TestFormContext.Provider>
    </Form>
  )
}

interface SideBySideProjectTestProps extends RouteComponentProps {
  testId?: string
  isLoadingProject: boolean
  children?: React.ReactNode
}

export const SideBySideProjectTest: React.FC<SideBySideProjectTestProps> = ({ testId, isLoadingProject }) => {
  const [test, setTest] = useState<SideBySideTest>(null)
  const { project } = useSideBySideProjectDetailsStore()
  const intl = useScopedIntl('')

  useEffect(() => {
    if (!testId || !project?.id || isLoadingProject) return

    fetchSideBySideTest(
      { projectId: project.id, testId },
      {
        onSuccess: setTest,
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }, [testId, project?.id, isLoadingProject])

  return test?.id ? (
    <TestFormWrapper test={test}>
      <TestForm recordId={testId} />
    </TestFormWrapper>
  ) : (
    <DatacLoading isLoading />
  )
}
