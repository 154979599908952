import { RouteComponentProps, Router } from '@reach/router'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { SorterOrder, fetchCenters, fetchProject } from '../../../requests'
import { RedirectNoAccessWrapper } from '../../RedirectNoAccessWrapper'
import { UserContext } from '../../auth'
import { DatacMessage } from '../../common'
import { SideBySideProjectBuilderContent } from './SideBySideProjectBuilderContent'
import { useSideBySideProjectDetailsStore } from './SideBySideProjectDetailsStore'
import { SideBySideProjectProducts } from './SideBySideProjectProducts'
import { SideBySideProjectReports } from './SideBySideProjectReports'
import { SideBySideProjectSettings } from './SideBySideProjectSettings'
import { SideBySideProjectTest } from './SideBySideProjectTest'
import { SideBySideProjectTests } from './SideBySideProjectTests'

interface Props extends RouteComponentProps {
  projectId?: string
}

export const SideBySideProjectDetailsContent: React.FC<Props> = ({ projectId }) => {
  const { user } = useContext(UserContext)
  const intl = useScopedIntl('')
  const { setProject, setCenters, clearProjectDetails } = useSideBySideProjectDetailsStore()
  const [isLoadingProject, setIsLoadingProject] = useState(true)

  useEffect(() => {
    if (user.shouldShowNextReleaseInfo()) {
      DatacMessage.nextReleaseInfo(intl, user.getNextRelease(), user.closeNextReleaseNotes, user.language)
    }
  }, [user])

  useEffect(() => {
    clearProjectDetails()

    if (!projectId) return

    setIsLoadingProject(true)
    fetchProject(
      { projectId },
      {
        onSuccess: project => {
          setProject(project)
          setIsLoadingProject(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsLoadingProject(false)
        }
      }
    )

    fetchCenters(
      { options: { sorter: { field: 'abbreviation', order: SorterOrder.Ascend } } },
      {
        onSuccess: ({ centers }) => setCenters(centers),
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }, [projectId])

  return (
    <Router>
      <RedirectNoAccessWrapper hasNoAccess={!user.isSideBySideEnabled} path="/">
        <SideBySideProjectTest path=":testId/*" isLoadingProject={isLoadingProject} />
        <SideBySideProjectTests path="/" isLoadingProject={isLoadingProject} />
      </RedirectNoAccessWrapper>
      <RedirectNoAccessWrapper hasNoAccess={!user.isSideBySideEnabled} path="reports">
        <SideBySideProjectReports path="*" />
      </RedirectNoAccessWrapper>
      <RedirectNoAccessWrapper hasNoAccess={!user.isSideBySideEnabled} path="products">
        <SideBySideProjectProducts path="*" />
      </RedirectNoAccessWrapper>
      <RedirectNoAccessWrapper hasNoAccess={!user.isSideBySideEnabled} path="settings">
        <SideBySideProjectSettings path="*" />
      </RedirectNoAccessWrapper>
      <RedirectNoAccessWrapper hasNoAccess={!user.isSideBySideEnabled} path="builder">
        <SideBySideProjectBuilderContent path="*" />
      </RedirectNoAccessWrapper>
    </Router>
  )
}
