import { useMatch } from '@reach/router'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useCallback, useReducer } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  Block,
  CreateFromStructureResponseHandlers,
  CreateFromTemplatesResponseHandlers,
  DeleteBlockResponseHandlers,
  Feature,
  FetchSectionResponseHandlers,
  FetchStructureResponseHandlers,
  InsertBlockTemplatesOptions,
  InsertTemplatesResponseHandlers,
  QuestionType,
  SaveBlockResponseHandlers,
  SaveBlockTemplateOptions,
  SaveFullTemplateOptions,
  SaveSectionTemplateOptions,
  SaveSubsectionTemplateOptions,
  SaveTemplateResponseHandlers,
  SearchQuestionsResponseHandlers,
  StaticContentType,
  Structure,
  UpdateSectionOptions,
  UpdateSectionResponseHandlers,
  UpdateStructureResponseHandlers,
  UpdateSubsectionOptions,
  UpdateSubsectionResponseHandlers,
  createProjectFromFullTemplates,
  createProjectFromSectionTemplates,
  createProjectFromSubsectionTemplates,
  deleteBlock as deleteBlockRequest,
  fetchProjectSection,
  fetchProjectStructure,
  insertProjectBlockTemplates,
  insertProjectFullTemplates,
  insertProjectSectionTemplates,
  insertProjectSubsectionTemplates,
  saveBlock as saveBlockRequest,
  saveBlockTemplate as saveBlockTemplateRequest,
  saveFullTemplate as saveFullTemplateRequest,
  saveSectionTemplate as saveSectionTemplateRequest,
  saveSubsectionTemplate as saveSubsectionTemplateRequest,
  searchProjectQuestions,
  updateProjectSection,
  updateProjectStructure,
  updateProjectSubsection
} from '../../../../requests'
import { routes } from '../../../../routes'
import { getConditionalQuestionById } from '../../../../utils'
import {
  Builder,
  BuilderContext,
  BuilderProps,
  BuilderTab,
  builderInitialState,
  builderStateReducer
} from '../../../shared/Builder'

const availableQuestionTabs = new Set([BuilderTab.Edit])

const availableQuestionTypes = new Set([
  QuestionType.Text,
  QuestionType.Checkbox,
  QuestionType.Radio,
  QuestionType.Dropdown,
  QuestionType.DateTime,
  QuestionType.Number,
  QuestionType.File
])

interface SideBySideProjectBuilderWrapperProps {
  projectId: string
  children?: React.ReactNode
}

const SideBySideProjectBuilderWrapper: React.FC<SideBySideProjectBuilderWrapperProps> = ({ projectId, children }) => {
  const urlParams = useMatch(`/:lang${routes.sideBySideProjectBuilderSection(projectId)}`)
  const [builderState, builderDispatch] = useReducer(builderStateReducer, builderInitialState)
  const intlBuilderLocked = useScopedIntl('side_by_side.project.builder.locked')

  const requestStudyIds = {
    projectId,
    studyId: null as string
  }

  const fetchSection = useCallback(
    ({ sectionId }: { sectionId: string }, responseHandlers?: FetchSectionResponseHandlers) => {
      return fetchProjectSection({ sectionId, ...requestStudyIds }, responseHandlers)
    },
    [projectId]
  )

  const fetchStructure = useCallback(
    (responseHandlers?: FetchStructureResponseHandlers) => {
      return fetchProjectStructure(requestStudyIds, responseHandlers)
    },
    [projectId]
  )

  const updateStructure = useCallback(
    ({ structure }: { structure: Structure }, responseHandlers?: UpdateStructureResponseHandlers) => {
      return updateProjectStructure({ structure, ...requestStudyIds }, responseHandlers)
    },
    [projectId]
  )

  const updateSection = useCallback(
    (options: UpdateSectionOptions, responseHandlers?: UpdateSectionResponseHandlers) => {
      return updateProjectSection({ ...requestStudyIds, ...options }, responseHandlers)
    },
    [projectId]
  )

  const updateSubsection = useCallback(
    (options: UpdateSubsectionOptions, responseHandlers?: UpdateSubsectionResponseHandlers) => {
      return updateProjectSubsection({ ...requestStudyIds, ...options }, responseHandlers)
    },
    [projectId]
  )

  const insertFullTemplates = useCallback(
    ({ templateIds }: { templateIds: string[] }, responseHandlers?: InsertTemplatesResponseHandlers) => {
      return insertProjectFullTemplates({ ...requestStudyIds, templateId: templateIds[0] }, responseHandlers)
    },
    [projectId]
  )

  const insertSectionTemplates = useCallback(
    ({ templateIds }: { templateIds: string[] }, responseHandlers?: InsertTemplatesResponseHandlers) => {
      return insertProjectSectionTemplates({ ...requestStudyIds, templateIds }, responseHandlers)
    },
    [projectId]
  )

  const insertSubsectionTemplates = useCallback(
    (
      { sectionId, templateIds }: { sectionId: string; templateIds: string[] },
      responseHandlers?: InsertTemplatesResponseHandlers
    ) => {
      return insertProjectSubsectionTemplates({ ...requestStudyIds, sectionId, templateIds }, responseHandlers)
    },
    [projectId]
  )

  const insertBlockTemplates = useCallback(
    (
      { subsectionId, templateIds }: InsertBlockTemplatesOptions,
      responseHandlers?: InsertTemplatesResponseHandlers
    ) => {
      return insertProjectBlockTemplates({ ...requestStudyIds, subsectionId, templateIds }, responseHandlers)
    },
    [projectId]
  )

  const createFromStructure = useCallback(
    ({ structure }: { structure: Structure }, responseHandlers?: CreateFromStructureResponseHandlers) => {
      return updateProjectStructure({ ...requestStudyIds, structure }, responseHandlers)
    },
    [projectId]
  )

  const createFromFullTemplates = useCallback(
    ({ templateIds }: { templateIds: string[] }, responseHandlers?: InsertTemplatesResponseHandlers) => {
      return createProjectFromFullTemplates({ ...requestStudyIds, templateId: templateIds[0] }, responseHandlers)
    },
    [projectId]
  )

  const createFromSectionTemplates = useCallback(
    ({ templateIds }: { templateIds: string[] }, responseHandlers?: CreateFromTemplatesResponseHandlers) => {
      return createProjectFromSectionTemplates({ ...requestStudyIds, templateIds }, responseHandlers)
    },
    [projectId]
  )

  const createFromSubsectionTemplates = useCallback(
    ({ templateIds }: { templateIds: string[] }, responseHandlers?: CreateFromTemplatesResponseHandlers) => {
      return createProjectFromSubsectionTemplates({ ...requestStudyIds, templateIds }, responseHandlers)
    },
    [projectId]
  )

  const saveFullTemplate = useCallback(
    (options: SaveFullTemplateOptions, responseHandlers?: SaveTemplateResponseHandlers) => {
      return saveFullTemplateRequest(
        { ...requestStudyIds, moduleType: Feature.SideBySide, ...options },
        responseHandlers
      )
    },
    [projectId]
  )

  const saveSectionTemplate = useCallback(
    (options: SaveSectionTemplateOptions, responseHandlers?: SaveTemplateResponseHandlers) => {
      return saveSectionTemplateRequest(
        { ...requestStudyIds, moduleType: Feature.SideBySide, ...options },
        responseHandlers
      )
    },
    [projectId]
  )

  const saveSubsectionTemplate = useCallback(
    (options: SaveSubsectionTemplateOptions, responseHandlers?: SaveTemplateResponseHandlers) => {
      return saveSubsectionTemplateRequest(
        { ...requestStudyIds, moduleType: Feature.SideBySide, ...options },
        responseHandlers
      )
    },
    [projectId]
  )

  const saveBlockTemplate = useCallback(
    (options: SaveBlockTemplateOptions, responseHandlers?: SaveTemplateResponseHandlers) => {
      return saveBlockTemplateRequest(
        { ...requestStudyIds, moduleType: Feature.SideBySide, ...options },
        responseHandlers
      )
    },
    [projectId]
  )

  const saveBlock = useCallback(
    (
      {
        sectionId,
        subsectionId,
        order,
        block
      }: { sectionId: string; subsectionId: string; order: number; block: Block },
      responseHandlers?: SaveBlockResponseHandlers
    ) => {
      return saveBlockRequest(
        { target: Feature.SideBySide, sectionId, subsectionId, order, block, ...requestStudyIds },
        responseHandlers
      )
    },
    [projectId]
  )

  const deleteBlock = useCallback(
    (
      { sectionId, subsectionId, blockId }: { sectionId: string; subsectionId: string; blockId: string },
      responseHandlers?: DeleteBlockResponseHandlers
    ) => {
      return deleteBlockRequest(
        { target: Feature.SideBySide, sectionId, subsectionId, blockId, ...requestStudyIds },
        responseHandlers
      )
    },
    [projectId]
  )

  const sectionRoute = useCallback(
    (sectionId: string) => routes.sideBySideProjectBuilderSection(projectId, sectionId),
    [projectId]
  )

  const searchQuestions = useCallback(
    ({ search }: { search: string }, responseHandlers?: SearchQuestionsResponseHandlers) => {
      return searchProjectQuestions({ search, ...requestStudyIds }, responseHandlers)
    },
    [projectId]
  )

  const placeholderText = (
    <>
      <p>{intlBuilderLocked('title')}</p>
      <p>{intlBuilderLocked('message')}</p>
    </>
  )

  const goToStructureBuilder = useCallback(() => {
    navigate(routes.sideBySideProjectBuilderStructure(projectId))
  }, [projectId])

  return (
    <BuilderContext.Consumer>
      {() => (
        <BuilderContext.Provider
          value={{
            ...builderState,
            builderDispatch,
            target: Feature.SideBySide,
            getConditionalQuestionById: (questionId: string) =>
              getConditionalQuestionById(builderState.conditionalQuestions, questionId),
            sectionId: urlParams?.sectionId,
            fetchSection,
            fetchStructure,
            insertFullTemplates,
            insertSectionTemplates,
            insertSubsectionTemplates,
            insertBlockTemplates,
            updateStructure,
            createFromStructure,
            createFromFullTemplates,
            createFromSectionTemplates,
            createFromSubsectionTemplates,
            updateSection,
            updateSubsection,
            saveSectionTemplate,
            saveSubsectionTemplate,
            saveBlockTemplate,
            saveFullTemplate,
            saveBlock,
            deleteBlock,
            goToStructureBuilder,
            isSupportingTemplates: true,
            availableQuestionTabs,
            availableQuestionTypes,
            availableStaticContentTypes: new Set(Object.values(StaticContentType)),
            sectionRoute,
            searchQuestions,
            placeholderText
          }}
        >
          {children}
        </BuilderContext.Provider>
      )}
    </BuilderContext.Consumer>
  )
}

export const SideBySideProjectBuilder: React.FC<BuilderProps> = props => (
  <SideBySideProjectBuilderWrapper projectId={props.studyId}>
    <Builder {...props} />
  </SideBySideProjectBuilderWrapper>
)
