import { FormInstance } from 'antd/lib/form'
import React, { createContext } from 'react'

import {
  Answer,
  DeleteAnswerResponseHandlers,
  DeleteFileResponseHandlers,
  DownloadFileAnswerOptions,
  DownloadFileResponseHandlers,
  FetchStructureResponseHandlers,
  FetchSubsectionResponseHandlers,
  FileAnswer,
  SaveAnswerResponseHandlers,
  SaveFileAnswerResponseHandlers,
  SaveFileResponseOptions,
  SideBySideTest
} from '../../../../../requests'
import { TestFormStateActions } from './TestFormReducer'

export const minColumnWidth = 300

export interface ITestFormState {
  isReadyToShow: boolean
  record: SideBySideTest
  unansweredRequiredQuestions: Set<string>
  requiredQuestionsAlert: boolean
  answeredQuestions: Set<string>
  sectionId: string
  subsectionId: string
  updateRecord: (shouldUpdateSubsection?: boolean) => void
  updateSubsection: () => void
  fetchStructure: (responseHandlers?: FetchStructureResponseHandlers) => void
  fetchSubsection: (
    options: { sectionId: string; subsectionId: string },
    responseHandlers?: FetchSubsectionResponseHandlers
  ) => void
  isEditingAvailable: boolean
  navigateToSubsection: (sectionId: string, subsectionId: string) => void
  saveAnswer: (
    options: {
      questionId: string
      productId: string
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      answer: any
    },
    responseHandlers?: SaveAnswerResponseHandlers<Answer>
  ) => void
  deleteAnswer: (
    options: { questionId: string; productId: string },
    responseHandlers?: DeleteAnswerResponseHandlers
  ) => void
  saveFile: (options: SaveFileResponseOptions, responseHandlers?: SaveFileAnswerResponseHandlers<FileAnswer>) => void
  deleteFile: (options: { fileId: string }, responseHandlers?: DeleteFileResponseHandlers) => void
  downloadFile: (options: DownloadFileAnswerOptions, responseHandlers?: DownloadFileResponseHandlers) => void
  maxFileBytes: number
  testFormDispatch: React.Dispatch<TestFormStateActions>
  formInstance: FormInstance
  maxFilesNumber: number
  showProductsLeftArrow: boolean
  showProductsRightArrow: boolean
  columnWidth: number
}

export const testFormInitialState: ITestFormState = {
  isReadyToShow: false,
  record: null,
  unansweredRequiredQuestions: new Set(),
  requiredQuestionsAlert: false,
  answeredQuestions: new Set(),
  sectionId: null,
  subsectionId: null,
  updateRecord: () => null,
  updateSubsection: () => null,
  fetchStructure: () => null,
  fetchSubsection: () => null,
  isEditingAvailable: false,
  navigateToSubsection: () => null,
  saveAnswer: () => null,
  deleteAnswer: () => null,
  deleteFile: () => null,
  saveFile: () => null,
  downloadFile: () => null,
  maxFileBytes: null,
  testFormDispatch: () => null,
  formInstance: null,
  maxFilesNumber: 1,
  showProductsLeftArrow: false,
  showProductsRightArrow: false,
  columnWidth: minColumnWidth
}

export const TestFormContext = createContext<ITestFormState>(testFormInitialState)
