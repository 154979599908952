import './TestFormQuestion.less'

import { Form, Image, Spin } from 'antd'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../../../hooks'
import {
  DateTimeQuestion,
  FileAnswer,
  FileQuestion,
  FileState,
  Question as IQuestion,
  NumberQuestion,
  QuestionType,
  QuestionTypeMapping,
  RecordStatus,
  SelectQuestion,
  TextQuestion,
  fetchFileUrls
} from '../../../../../../requests'
import { isAnswerValueEmpty, proxyMinioToLocalhost } from '../../../../../../utils'
import { DatacIcon, DatacImagePlaceholder, DatacTitle } from '../../../../../common'
import { TestFormAction } from '../TestFormReducer'
import { PropsFromContext, PropsFromContextKeys, TestFormContextWrapper, getFieldName } from '../TestFormUtils'
import { CheckboxQuestionControl } from './CheckboxQuestionControl'
import { DatetimeQuestionControl } from './DatetimeQuestionControl'
import { DropdownQuestionControl } from './DropdownQuestionControl'
import { FileQuestionControl } from './FileQuestionControl'
import { NumberQuestionControl, isNumberQuestionAnswerWrong } from './NumberQuestionControl'
import { RadioQuestionControl } from './RadioQuestionControl'
import { TextQuestionControl } from './TextQuestionControl'

enum AnswerStatus {
  Success = 'success',
  Error = 'error',
  Saving = 'saving'
}

interface ProductFieldState {
  productId: number
  isTouched: boolean
  isAnswered: boolean
  saveStatus?: AnswerStatus
}

export const renderQuestionControl = (
  question: IQuestion,
  productId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void,
  onFilesChange: (oldFiles: FileAnswer[], newFiles: FileAnswer[], errors: string[]) => void,
  disabled: boolean
) => {
  switch (question.type) {
    case QuestionType.Text:
      return <TextQuestionControl onChange={onChange} question={question as TextQuestion} disabled={disabled} />
    case QuestionType.Number:
      return <NumberQuestionControl onChange={onChange} question={question as NumberQuestion} disabled={disabled} />
    case QuestionType.Checkbox:
      return <CheckboxQuestionControl onChange={onChange} question={question as SelectQuestion} disabled={disabled} />
    case QuestionType.Radio:
      return <RadioQuestionControl onChange={onChange} question={question as SelectQuestion} disabled={disabled} />
    case QuestionType.Dropdown:
      return <DropdownQuestionControl onChange={onChange} question={question as SelectQuestion} disabled={disabled} />
    case QuestionType.DateTime:
      return <DatetimeQuestionControl onChange={onChange} question={question as DateTimeQuestion} disabled={disabled} />
    case QuestionType.File:
      return (
        <FileQuestionControl
          productId={productId}
          question={question as FileQuestion}
          onFilesChange={onFilesChange}
          disabled={disabled}
        />
      )
    default:
      return null
  }
}

interface TestFormQuestionControlProps {
  question: IQuestion
  productId: number
  state: ProductFieldState
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (productId: number) => (value: any) => void
  onFilesChange: (productId: number) => (oldFiles: FileAnswer[], newFiles: FileAnswer[]) => void
  columnWidth: number
  disabled: boolean
}

const TestFormQuestionControl: React.FC<TestFormQuestionControlProps> = ({
  question,
  productId,
  state,
  onChange,
  onFilesChange,
  columnWidth,
  disabled
}) => {
  const intlIndicator = useScopedIntl('studies.fulfillment.question.indicator')
  const [showIndicator, setShowIndicator] = useState(false)

  useEffect(() => {
    setShowIndicator(!!state?.saveStatus)
    if (state?.saveStatus === AnswerStatus.Success) debouncedRemoveSave()
  }, [state])

  const debouncedRemoveSave = useCallback(
    debounce(() => setShowIndicator(false), 3000),
    [productId]
  )
  const debouncedOnChange = useCallback(debounce(onChange(productId), 1000), [onChange, productId])

  return (
    <div
      className="test-form-question__control"
      style={{ width: question.isGlobal ? '100%' : `${columnWidth}px`, minWidth: `${columnWidth}px` }}
    >
      <div className="test-form-question__control__header-right">
        {showIndicator && (
          <>
            {state.saveStatus === AnswerStatus.Saving && <Spin size="small" />}
            <span
              className={classNames(
                'test-form-question__indicator',
                'test-form-question__indicator--success',
                state.saveStatus !== AnswerStatus.Success && 'test-form-question__indicator--hidden'
              )}
            >
              <DatacIcon className="test-form-question__indicator__icon" name="check" raw /> {intlIndicator('success')}
            </span>
            <span
              className={classNames(
                'test-form-question__indicator',
                'test-form-question__indicator--error',
                state.saveStatus !== AnswerStatus.Error && 'test-form-question__indicator--hidden'
              )}
            >
              <DatacIcon className="test-form-question__indicator__icon" name="x" raw /> {intlIndicator('error')}
            </span>
          </>
        )}
      </div>

      <Form.Item name={getFieldName(question.id, productId)}>
        {renderQuestionControl(
          question,
          productId ? String(productId) : null,
          debouncedOnChange,
          onFilesChange(productId),
          disabled
        )}
      </Form.Item>
    </div>
  )
}

interface Props {
  question: IQuestion
}

const contextProps: PropsFromContextKeys = [
  'updateRecord',
  'saveAnswer',
  'deleteAnswer',
  'requiredQuestionsAlert',
  'record',
  'testFormDispatch',
  'formInstance',
  'answeredQuestions',
  'unansweredRequiredQuestions',
  'columnWidth'
]

const TestFormQuestionInner: React.FC<Props & PropsFromContext> = ({
  question,
  updateRecord,
  saveAnswer,
  deleteAnswer,
  requiredQuestionsAlert,
  record,
  testFormDispatch,
  formInstance,
  answeredQuestions,
  unansweredRequiredQuestions,
  columnWidth
}) => {
  const [descriptionImageState, setDescriptionImageState] = useState<FileState>(FileState.Preloading)
  const [descriptionImageUrl, settDescriptionImageUrl] = useState<string>(undefined)
  const [productsFieldsStates, setProductsFieldsStates] = useState<ProductFieldState[]>([])
  const [questionError, setQuestionError] = useState<boolean>(false)
  const headerRef = useRef<HTMLDivElement>(null)
  const headerWrapperRef = useRef<HTMLDivElement>(null)
  const globalQuestionRef = useRef<HTMLDivElement>(null)
  const globalQuestionWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setQuestionError(
      unansweredRequiredQuestions.has(question.id) &&
        (record.products.every(product => productsFieldsStates.find(s => s.productId === product.id)?.isTouched) ||
          requiredQuestionsAlert)
    )
  }, [question.id, record.products, unansweredRequiredQuestions.size, requiredQuestionsAlert])

  const commonUpdateQuestionDependencies = [question.id, record?.id, updateRecord]

  const updateProductFieldState = useCallback(
    (productId: number, newState: Partial<ProductFieldState>) =>
      setProductsFieldsStates(current => [
        ...current.filter(p => p.productId !== productId),
        { ...current.find(p => p.productId === productId), ...newState }
      ]),
    [...commonUpdateQuestionDependencies, setProductsFieldsStates]
  )

  useEffect(() => {
    if (question.descriptionImage?.fileId) {
      setDescriptionImageState(FileState.Preloading)
      fetchFileUrls(
        {
          fileIds: [question.descriptionImage?.fileId]
        },
        {
          onSuccess: fileUrls => {
            setDescriptionImageState(FileState.Ready)
            settDescriptionImageUrl(
              fileUrls[question.descriptionImage?.fileId]
                ? proxyMinioToLocalhost(fileUrls[question.descriptionImage?.fileId])
                : undefined
            )
          },
          onRequestError: () => {
            setDescriptionImageState(FileState.Error)
            settDescriptionImageUrl(undefined)
          }
        }
      )
    }
  }, [question.descriptionImage?.fileId])

  useEffect(() => {
    if (question?.required) {
      const isAnswered = question.isGlobal
        ? answeredQuestions.has(getFieldName(question.id, 0))
        : record.products.every(product => answeredQuestions.has(getFieldName(question.id, product.id)))

      testFormDispatch({
        type: TestFormAction.UPDATE_UNANSWERED_REQUIRED_QUESTIONS,
        questionId: question.id,
        isAnswered
      })
    }

    if (question.isGlobal) {
      setProductsFieldsStates([
        {
          productId: 0,
          isTouched: false,
          isAnswered: answeredQuestions.has(getFieldName(question.id, 0))
        }
      ])
    } else {
      setProductsFieldsStates(current =>
        record.products.map(
          product =>
            current.find(c => c.productId === product.id) || {
              productId: product.id,
              isTouched: false,
              isAnswered: answeredQuestions.has(getFieldName(question.id, product.id))
            }
        )
      )
    }
  }, [question.id, record.products])

  const onValueUpdateStart = useCallback(
    (isAnswerAdded: boolean, productId: number) => {
      testFormDispatch({
        type: TestFormAction.UPDATE_ANSWERED_QUESTIONS,
        questionId: question.id,
        productId,
        isAnswerAdded
      })

      if (!isAnswerAdded) formInstance.resetFields([getFieldName(question.id, productId)])

      updateProductFieldState(productId, { saveStatus: AnswerStatus.Saving, isAnswered: isAnswerAdded })
    },
    [...commonUpdateQuestionDependencies, updateProductFieldState]
  )

  const onValueUpdateSuccess = (productId: number) => {
    updateProductFieldState(productId, { saveStatus: AnswerStatus.Success, isTouched: true })
    updateRecord()
  }

  const onDeleteAnswer = useCallback(
    (productId: number) => {
      onValueUpdateStart(false, productId)
      deleteAnswer(
        { questionId: question.id, productId: productId ? String(productId) : null },
        {
          onSuccess: () => onValueUpdateSuccess(productId),
          onAnswerDoesntExist: () => updateProductFieldState(productId, { saveStatus: AnswerStatus.Success }),
          onRequestError: () => updateProductFieldState(productId, { saveStatus: AnswerStatus.Error })
        }
      )
    },
    [...commonUpdateQuestionDependencies]
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const questionValidators: { [key in QuestionType]?: (question: QuestionTypeMapping[key], value: any) => boolean } = {
    [QuestionType.Number]: (question, value) => !isNumberQuestionAnswerWrong(value, question)
  }

  const onValueChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any, productId: number) => {
      if (isAnswerValueEmpty(question.type, value)) {
        formInstance.resetFields([getFieldName(question.id, productId)])
        onDeleteAnswer(productId)
        return
      }

      if (
        question.type in questionValidators &&
        // @ts-expect-error
        // due to dynamic nature of question we can't predict type but questionValidators are secure enough to not allow any errors to happen
        !questionValidators[question.type](question, value)
      ) {
        return
      }
      onValueUpdateStart(true, productId)
      saveAnswer(
        {
          questionId: question.id,
          productId: productId ? String(productId) : null,
          answer: value
        },
        {
          onSuccess: () => onValueUpdateSuccess(productId),
          onRequestError: () => updateProductFieldState(productId, { saveStatus: AnswerStatus.Error })
        }
      )
    },
    [...commonUpdateQuestionDependencies, onDeleteAnswer]
  )

  const onFilesChange = (productId: number) => (oldFiles: FileAnswer[], newFiles: FileAnswer[]) => {
    const isProcessing = newFiles.some(file => file.state === FileState.Uploading || file.state === FileState.Deleting)

    if (!isProcessing) {
      updateProductFieldState(productId, { isTouched: true, isAnswered: !!newFiles.length })

      if (newFiles.length) {
        testFormDispatch({
          type: TestFormAction.UPDATE_ANSWERED_QUESTIONS,
          questionId: question.id,
          productId,
          isAnswerAdded: true
        })
      } else if (oldFiles.length) {
        testFormDispatch({
          type: TestFormAction.UPDATE_ANSWERED_QUESTIONS,
          questionId: question.id,
          productId,
          isAnswerAdded: false
        })
      }

      if (question.required) {
        testFormDispatch({
          type: TestFormAction.UPDATE_UNANSWERED_REQUIRED_QUESTIONS,
          questionId: question.id,
          isAnswered: record.products.every(product => answeredQuestions.has(getFieldName(question.id, product.id)))
        })
      }
    }
  }

  const onChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (productId: number) => (value: any) => onValueChange(value, productId),
    [...commonUpdateQuestionDependencies, onValueChange]
  )

  useEffect(() => {
    if (!headerRef.current || !headerWrapperRef.current || !question.title) return

    headerWrapperRef.current.style.height = `${headerRef.current.clientHeight}px`
  }, [headerRef.current, headerWrapperRef.current, question, columnWidth])

  useEffect(() => {
    if (!globalQuestionRef.current || !globalQuestionWrapperRef.current) return undefined

    const resizeObserver = new ResizeObserver(() => {
      globalQuestionWrapperRef.current.style.height = `${globalQuestionRef.current.clientHeight}px`
      globalQuestionWrapperRef.current.style.width = `${globalQuestionRef.current.clientWidth}px`
    })
    resizeObserver.observe(globalQuestionRef.current)

    return () => resizeObserver.disconnect()
  }, [globalQuestionRef.current, globalQuestionWrapperRef.current])

  return (
    <div
      className={classNames('test-form-question', questionError && 'test-form-question--error')}
      style={{ width: `${record.products.length * columnWidth}px` }}
    >
      <div className="test-form-question__header__wrapper" ref={headerWrapperRef}>
        <div className="test-form-question__header" ref={headerRef}>
          <div
            className={classNames(
              'test-form-question__header__left',
              { done: productsFieldsStates.filter(s => s.isAnswered).length === record.products.length },
              { global: question.isGlobal }
            )}
          >
            <DatacIcon name="check" raw />
            {productsFieldsStates.filter(s => s.isAnswered).length} / {record.products.length}
          </div>
          <DatacTitle type="h4" size="small" className="test-form-question__title">
            {question.title} {question.required && <span className="test-form-question__required-mark">*</span>}
          </DatacTitle>
          {!!question.description && <div className="test-form-question__description">{question.description}</div>}
          {!!question.descriptionImage && (
            <Image
              className="test-form-question__image-description"
              src={descriptionImageUrl}
              placeholder={<DatacImagePlaceholder state={descriptionImageState} />}
            />
          )}
        </div>
      </div>
      {question.isGlobal ? (
        <div className="test-form-question__global__wrapper" ref={globalQuestionWrapperRef}>
          <div className="test-form-question__global" ref={globalQuestionRef}>
            <TestFormQuestionControl
              question={question}
              productId={0}
              state={productsFieldsStates.find(s => s.productId === 0)}
              onChange={onChange}
              onFilesChange={onFilesChange}
              columnWidth={columnWidth}
              disabled={record.status === RecordStatus.Ended}
            />
          </div>
        </div>
      ) : (
        <div className="test-form-question__list">
          {record.products.map(product => (
            <TestFormQuestionControl
              question={question}
              productId={product.id}
              state={productsFieldsStates.find(s => s.productId === product.id)}
              onChange={onChange}
              onFilesChange={onFilesChange}
              key={product.id}
              columnWidth={columnWidth}
              disabled={record.status === RecordStatus.Ended}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export const TestFormQuestion = TestFormContextWrapper<Props>(TestFormQuestionInner, contextProps)
