import './SideBySideProjectReports.less'

import { RouteComponentProps } from '@reach/router'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { SideBySideChart, fetchSideBySideCharts, removeSideBySideChart } from '../../../../requests'
import { DatacIcon, DatacMessage } from '../../../common'
import { SideBySideProjectDetailsLayout, SideBySideProjectDetailsTab } from '../SideBySideProjectDetailsLayout'
import { useSideBySideProjectDetailsStore } from '../SideBySideProjectDetailsStore'
import { ChartListItem } from './ChartListItem'
import { ChartWizard } from './ChartWizard'

const MAX_CHART_COUNT = 25

export const SideBySideProjectReports: React.FC<RouteComponentProps> = () => {
  const intl = useScopedIntl('')
  const intlReports = useScopedIntl('side_by_side.project.reports')
  const { project } = useSideBySideProjectDetailsStore()
  const [charts, setCharts] = useState<SideBySideChart[]>([])
  const [isFetching, setIsFetching] = useState(false)
  const [wizardChart, setWizardChart] = useState<SideBySideChart>(null)
  const [isWizardOpen, setIsWizardOpen] = useState(false)
  const [disabledItem, setDisabledItem] = useState<number>(null)

  useEffect(() => {
    if (!project) return
    fetchCharts()
  }, [project])

  const fetchCharts = () => {
    setIsFetching(true)

    fetchSideBySideCharts(
      {
        projectId: project.id
      },
      {
        onSuccess: results => {
          setCharts(results)
          setIsFetching(false)
        },
        onRequestError: () => {
          DatacMessage.genericError(intl)
          setIsFetching(false)
        }
      }
    )
  }

  const onCloseChartWizard = (reload?: boolean) => {
    setWizardChart(null)
    setIsWizardOpen(false)
    if (reload) fetchCharts()
  }

  const onCreateNewChart = () => {
    setWizardChart(null)
    setIsWizardOpen(true)
  }

  const onEditChart = (chart: SideBySideChart) => {
    setWizardChart(chart)
    setIsWizardOpen(true)
  }

  const onDeleteChart = (id: number) => {
    setDisabledItem(id)
    removeSideBySideChart(
      {
        projectId: project.id,
        id
      },
      {
        onSuccess: () => {
          setDisabledItem(null)
          fetchCharts()
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <SideBySideProjectDetailsLayout currentTab={SideBySideProjectDetailsTab.Reports}>
      {project?.id && !isFetching && (
        <div className="side_by_side-project-reports">
          {charts.length < MAX_CHART_COUNT && (
            <Button
              className="side_by_side-project-reports__add-chart"
              type="primary"
              size="large"
              onClick={onCreateNewChart}
            >
              <DatacIcon type="blue" name="plus" size="big" />
              {intlReports('new_chart')}
            </Button>
          )}
          {charts.map(chart => (
            <ChartListItem
              key={chart.id}
              chart={chart}
              onDelete={() => onDeleteChart(chart.id)}
              onEdit={() => onEditChart(chart)}
              disabled={disabledItem === chart.id}
            />
          ))}
          {isWizardOpen && <ChartWizard onClose={onCloseChartWizard} chart={wizardChart} />}
        </div>
      )}
    </SideBySideProjectDetailsLayout>
  )
}
