import { Form } from 'antd'
import { Color } from 'antd/es/color-picker'
import arrayMove from 'array-move'
import React from 'react'
// TODO: replace with dnd-kit when new builder is merged
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

import { useScopedIntl } from '../../../../../hooks'
import { SideBySideChartFormula } from '../../../../../requests'
import { DatacColorPicker, DatacIcon } from '../../../../common'

const DragHandle = SortableHandle(() => <DatacIcon name="moreVertical" raw />)

interface FormulaListItemProps {
  formula: SideBySideChartFormula
  onChange: (formula: SideBySideChartFormula) => void
}

const FormulaListItem = SortableElement(({ formula, onChange }: FormulaListItemProps) => {
  const [formInstance] = Form.useForm()
  const onColorChange = (color: Color) => {
    onChange({
      ...formula,
      color: `#${color.toHex()}`
    })
  }
  return (
    <div className="side_by_side-chart-wizard__formulas-editor__list-item">
      <div className="side_by_side-chart-wizard__formulas-editor__list-item__brand">{formula.brand_name}</div>
      <div className="side_by_side-chart-wizard__formulas-editor__list-item__number">{formula.formula_number}</div>
      <div className="side_by_side-chart-wizard__formulas-editor__list-item__category">{formula.category_name}</div>
      <div className="side_by_side-chart-wizard__formulas-editor__list-item__color-picker">
        <Form form={formInstance} initialValues={{ color: formula.color }}>
          <Form.Item
            name="color"
            noStyle
            getValueFromEvent={color => {
              onColorChange(color)
              return `#${color.toHex()}`
            }}
          >
            <DatacColorPicker showText={() => <DatacIcon name="chevronDown" raw />} />
          </Form.Item>
        </Form>
      </div>
      <div className="side_by_side-chart-wizard__formulas-editor__drag-handle">
        <DragHandle />
      </div>
    </div>
  )
})

interface FormulaListProps {
  formulas: SideBySideChartFormula[]
  onChange: (formulas: SideBySideChartFormula[]) => void
}

const FormulaList = SortableContainer(({ formulas, onChange }: FormulaListProps) => {
  const onItemChange = (changedformula: SideBySideChartFormula) => {
    onChange(
      formulas.map(formula => {
        if (formula.id !== changedformula.id) {
          return formula
        }
        return changedformula
      })
    )
  }
  return (
    <div className="side_by_side-chart-wizard__formulas-editor__list">
      {formulas.map((formula, index) => (
        <FormulaListItem key={formula.id} index={index} formula={formula} onChange={onItemChange} />
      ))}
    </div>
  )
})

interface Props {
  formulas: SideBySideChartFormula[]
  onChange: (formulas: SideBySideChartFormula[]) => void
  openDrawer: () => void
}

export const FormulasEditor: React.FC<Props> = ({ formulas, onChange, openDrawer }) => {
  const intlChart = useScopedIntl('side_by_side.project.chart')
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    onChange(arrayMove(formulas, oldIndex, newIndex))
  }
  return (
    <div className="side_by_side-chart-wizard__formulas-editor">
      <FormulaList
        formulas={formulas}
        onChange={onChange}
        onSortEnd={onSortEnd}
        useDragHandle
        helperClass="side_by_side-chart-wizard__formulas-editor__list-item--dragged"
      />
      <div
        className="side_by_side-chart-wizard__formulas-editor__add-formula"
        onClick={openDrawer}
        role="button"
        tabIndex={0}
      >
        <DatacIcon name="plus" raw />
        {intlChart('add_formula')}
      </div>
    </div>
  )
}
