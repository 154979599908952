import './SideBySideProjectsContent.less'

import { RouteComponentProps, useLocation } from '@reach/router'
import { Input, Pagination } from 'antd'
import { debounce } from 'lodash'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'

import EmptyPlaceholderImage from '../../../assets/images/datacapt-empty.svg'
import { useScopedIntl } from '../../../hooks'
import { Project, ProjectCategory, ProjectStatus, fetchBrands, fetchProjects } from '../../../requests'
import { DatacLoading, DatacMessage, DatacPaginationItem, DatacTitle } from '../../common'
import { NewProject } from './NewProject'
import { Filters, ProjectsFilters, defaultFilters } from './ProjectsFilters'
import { ProjectsList } from './ProjectsList'

const pageSize = 25

export const SideBySideProjectsContent: React.FC<RouteComponentProps> = ({ navigate }) => {
  const location = useLocation()
  const queryParams = queryString.parse(location.search, { arrayFormat: 'comma' })
  const [isSearching, setIsSearching] = useState(false)
  const [searchPhrase, setSearchPhrase] = useState((queryParams.searchPhrase as string) || '')
  const [noProjectsAvailable, setNoProjectsAvailable] = useState(false)
  const intlSideBySide = useScopedIntl('side_by_side')
  const intl = useScopedIntl('')
  const [isFetchingProjectsList, setIsFetchingProjectsList] = useState(true)
  const [projects, setProjects] = useState<Project[]>(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [projectsCount, setProjectsCount] = useState(0)
  const [brands, setBrands] = useState<string[]>([])

  const getParsedFilterParam = <T extends string>(key: keyof Filters) =>
    ((queryParams[key] && (Array.isArray(queryParams[key]) ? queryParams[key] : [queryParams[key]])) ||
      defaultFilters[key]) as T[]

  const [filters, setFilters] = useState<Filters>({
    status: getParsedFilterParam<ProjectStatus>('status'),
    category: getParsedFilterParam<ProjectCategory>('category'),
    brand: getParsedFilterParam<string>('brand')
  })

  const getSearchAndFiltersQuery = (filters: Filters, phrase: string) => {
    return `?${queryString.stringify(
      { ...filters, searchPhrase: phrase },
      { arrayFormat: 'comma', skipNull: true, skipEmptyString: true }
    )}`
  }

  const isFilterActive = () => !!filters.status?.length || !!filters.category?.length || !!filters.brand?.length

  useEffect(() => {
    if (!location.search && (searchPhrase || isFilterActive())) {
      setFilters(defaultFilters)
      setSearchPhrase(null)
      fetchProjectsList(defaultFilters, null)
    }
  }, [location])

  const updateBrandList = () => {
    fetchBrands({
      onSuccess: setBrands,
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
  }

  const onFilter = (newFilters: Filters) => {
    setFilters(newFilters)
    fetchProjectsList(newFilters, searchPhrase, 0)
    navigate(getSearchAndFiltersQuery(newFilters, searchPhrase), { replace: true })
  }
  const onSearch = debounce((newPhrase: string) => {
    setIsSearching(true)
    fetchProjectsList(filters, newPhrase, 0)
    navigate(getSearchAndFiltersQuery(filters, newPhrase), { replace: true })
  }, 1000)

  const onPageChange = (page: number) => {
    fetchProjectsList(filters, searchPhrase, page - 1)
  }

  const fetchProjectsList = (filters: Filters, search: string, page = currentPage) => {
    fetchProjects(
      {
        options: {
          limit: pageSize,
          offset: page * pageSize,
          search,
          status: filters.status,
          category: filters.category,
          brand: filters.brand
        }
      },
      {
        onSuccess: (newProjects, { projectsCount, allProjectsCount }) => {
          setProjects(newProjects)
          setProjectsCount(projectsCount)
          setCurrentPage(page + 1)
          setIsFetchingProjectsList(false)
          setNoProjectsAvailable(allProjectsCount === 0)
          setIsSearching(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingProjectsList(false)
        }
      }
    )
  }

  useEffect(() => {
    onReload()
  }, [])

  const onReload = () => {
    fetchProjectsList(filters, searchPhrase)
    updateBrandList()
  }

  return (
    <main className="side-by-side-projects-content">
      <div className="side-by-side-projects-content__header">
        <DatacTitle type="h1">{intlSideBySide('title')}</DatacTitle>
        {projects && !noProjectsAvailable && (
          <div className="recruitment-studies-content__controls">
            <Input.Search
              id="recruitment-studies-search"
              className="recruitment-studies-content__search"
              size="large"
              onChange={e => {
                setSearchPhrase(e.target.value)
                onSearch(e.target.value)
              }}
              placeholder={intl('common.search')}
              loading={isSearching}
              value={searchPhrase}
            />
            <ProjectsFilters onFilter={onFilter} initialValues={filters} isActive={isFilterActive()} brands={brands} />
            <NewProject brands={brands} />
          </div>
        )}
      </div>
      <DatacLoading isLoading={isFetchingProjectsList}>
        {!!projects?.length && (
          <>
            <ProjectsList projects={projects} />
            {projectsCount > pageSize && (
              <div className="side-by-side-projects-content__pagination-wrapper">
                <Pagination
                  current={currentPage}
                  itemRender={DatacPaginationItem}
                  onChange={onPageChange}
                  total={projectsCount}
                  showSizeChanger={false}
                  pageSize={pageSize}
                />
              </div>
            )}
          </>
        )}
        {!projects?.length && (
          <div className="side-by-side-projects-content__no-projects">
            <EmptyPlaceholderImage className="side-by-side-projects-content__no-projects-image" />
            {noProjectsAvailable ? (
              <>
                <NewProject brands={brands} />
                {intlSideBySide('no_access_to_any')}
              </>
            ) : (
              intlSideBySide('no_results_for_filters')
            )}
          </div>
        )}
      </DatacLoading>
    </main>
  )
}
