import { RouteComponentProps } from '@reach/router'
import { Button, Form, Input, RefSelectProps } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import {
  CenterData,
  ProjectCategory,
  ProjectStatus,
  UpdateProjectOptions,
  fetchBrands,
  updateProject
} from '../../../../../requests'
import { enumToOptions } from '../../../../../utils'
import { maxTitleLengthLong, validateRequired } from '../../../../../validation'
import { DatacMessage, DatacOption, DatacSelect, DatacSettingsFormItem, DatacStatusSelect } from '../../../../common'
import { useSideBySideProjectDetailsStore } from '../../SideBySideProjectDetailsStore'

export const SideBySideProjectSettingsGeneral: React.FC<RouteComponentProps> = () => {
  const intlCategory = useScopedIntl('side_by_side.card.category')
  const intlField = useScopedIntl('side_by_side.projects.field')
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [form] = Form.useForm()
  const { project, centers, setProject } = useSideBySideProjectDetailsStore()
  const intl = useScopedIntl('')
  const [centersOptions, setCentersOptions] = useState<DatacOption[]>([])
  const [brandOptions, setBrandOptions] = useState<DatacOption[]>([])
  const [currentEnteredBrand, setCurrentEnteredBrand] = useState('')
  const [brand, setBrand] = useState('')
  const [isBrandFormatCorrect, setIsBrandFormatCorrect] = useState(true)
  const brandRef = useRef<RefSelectProps>()

  useEffect(() => {
    if (!project || !centers) return

    resetFields()
    updateBrandList()
    setCentersOptions(
      centers.map((center: CenterData) => ({
        value: center.id,
        label: center.abbreviation
      }))
    )
  }, [project, centers])

  const updateBrandList = () => {
    fetchBrands({
      onSuccess: brands => setBrandOptions(brands.map(brand => ({ value: brand, label: brand }))),
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
  }

  const statusOptions = {
    [ProjectStatus.Draft]: {
      value: ProjectStatus.Draft,
      label: intl('studies.status.draft'),
      className: 'side-by-side-project-settings__content__status-icon--draft'
    },
    [ProjectStatus.Live]: {
      value: ProjectStatus.Live,
      label: intl('studies.status.live'),
      className: 'side-by-side-project-settings__content__status-icon--live'
    },
    [ProjectStatus.Ended]: {
      value: ProjectStatus.Ended,
      label: intl('studies.status.ended'),
      className: 'side-by-side-project-settings__content__status-icon--ended'
    }
  }

  const statusTransitionOptions = {
    [ProjectStatus.Draft]: [
      statusOptions[ProjectStatus.Draft],
      statusOptions[ProjectStatus.Live],
      statusOptions[ProjectStatus.Ended]
    ],
    [ProjectStatus.Live]: [statusOptions[ProjectStatus.Live], statusOptions[ProjectStatus.Ended]],
    [ProjectStatus.Ended]: [statusOptions[ProjectStatus.Ended], statusOptions[ProjectStatus.Live]]
  }

  const inputDisabled = !isEditingOn

  const resetFields = () => {
    form.setFieldsValue({
      name: project.name,
      centerIds: project.centers?.map((center: CenterData) => center.id),
      status: project.status,
      brand: project.brand,
      category: project.category
    })
  }

  const onCancel = () => {
    setIsEditingOn(false)
    resetFields()
  }

  const onSave = () => {
    form.submit()
  }

  const onEdit = () => {
    setIsEditingOn(true)
  }

  const onSubmit = (data: UpdateProjectOptions) => {
    updateProject(
      { ...data, projectId: project.id },
      {
        onSuccess: ({ id, centerIds, category, brand, name, status }) => {
          setIsEditingOn(false)
          setProject({
            ...project,
            id,
            category,
            brand,
            name,
            status,
            centers: centers.filter((center: CenterData) => centerIds.includes(center.id))
          })
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onBrandSearch = (searchPhrase: string) => {
    setCurrentEnteredBrand(searchPhrase.substring(0, maxTitleLengthLong))
  }

  const setNewBrand = (brand: string) => {
    form.setFieldsValue({ brand })
    setBrand(brand)
  }

  const onBrandKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key !== 'Enter' ||
      !currentEnteredBrand.trim() ||
      brandOptions.find(({ value }) => value === currentEnteredBrand)
    ) {
      setIsBrandFormatCorrect(true)
      return
    }

    setBrandOptions(currentAvailableBrands => [
      ...currentAvailableBrands,
      { value: currentEnteredBrand, label: currentEnteredBrand }
    ])
    setNewBrand(currentEnteredBrand)
    onBrandSearch('')
    setCurrentEnteredBrand('')
    brandRef.current.blur()
  }

  const onBrandSelect = (selectedBrand: string) => {
    setNewBrand(brand === selectedBrand ? '' : selectedBrand)
  }

  return (
    <div className="side-by-side-project-settings__content__body">
      <div className="side-by-side-project-settings__content__form">
        <Form form={form} onFinish={onSubmit}>
          <DatacSettingsFormItem
            name="name"
            label={intlField('name.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <Input
              size="large"
              placeholder={intlField('name.placeholder')}
              maxLength={maxTitleLengthLong}
              disabled={inputDisabled}
            />
          </DatacSettingsFormItem>

          <DatacSettingsFormItem
            name="centerIds"
            label={intlField('center.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <DatacSelect
              showSearch
              mode="multiple"
              options={centersOptions}
              placeholder={intlField('center.placeholder')}
              disabled={inputDisabled}
            />
          </DatacSettingsFormItem>

          <DatacSettingsFormItem
            name="brand"
            label={intlField('brand.label')}
            error={isBrandFormatCorrect ? undefined : intlField('brand.validation')}
            validate={validateRequired(intl('common.required'))}
          >
            <DatacSelect
              size="large"
              placeholder={intlField('brand.placeholder')}
              showSearch
              options={brandOptions}
              onInputKeyDown={onBrandKeyPressed}
              onSearch={onBrandSearch}
              defaultActiveFirstOption={false}
              selectRef={brandRef}
              onSelect={onBrandSelect}
              disabled={inputDisabled}
              searchValue={currentEnteredBrand}
            />
          </DatacSettingsFormItem>
          <DatacSettingsFormItem
            name="category"
            label={intlField('category.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <DatacSelect
              showSearch
              options={enumToOptions(ProjectCategory, intlCategory)}
              placeholder={intlField('category.placeholder')}
              disabled={inputDisabled || project.status !== ProjectStatus.Draft}
            />
          </DatacSettingsFormItem>

          <DatacSettingsFormItem
            name="status"
            label={intlField('status.label')}
            validate={validateRequired(intl('common.required'))}
          >
            <DatacStatusSelect
              disabled={!isEditingOn}
              statusTransitionOptions={statusTransitionOptions[project.status as ProjectStatus]}
            />
          </DatacSettingsFormItem>
        </Form>
      </div>
      <div className="side-by-side-project-settings__content__form-controls">
        {isEditingOn ? (
          <>
            <Button size="large" type="default" onClick={onCancel}>
              {intl('common.cancel')}
            </Button>
            <Button size="large" type="primary" onClick={onSave}>
              {intl('common.save')}
            </Button>
          </>
        ) : (
          <Button size="large" type="primary" onClick={onEdit}>
            {intl('common.edit')}
          </Button>
        )}
      </div>
    </div>
  )
}
