import React from 'react'

import { Candidate, RecordStatus } from '../../../../requests'
import {
  ColumnFixedType,
  DatacOption,
  DatacRecordStatusTag,
  renderDateWithAuthor,
  scopedIntlType
} from '../../../common'

export const renderContact = (firstName: string, lastName: string, email: string) => (
  <div className="side_by_side-project-tests__name">
    <div className="side_by_side-project-tests__name__full-name">
      {firstName} {lastName}
    </div>
    {email}
  </div>
)

const renderStatus = (status: RecordStatus) => <DatacRecordStatusTag status={status} />

interface GetColumnsOptions {
  columnNames: {
    testId: string
    datacaptId: string
    creationDate: string
    status: string
    centerName: string
    lastUpdated: string
  }
}

export const getListColumns = ({ columnNames }: GetColumnsOptions) => {
  return [
    {
      title: columnNames.datacaptId,
      dataIndex: 'datacaptId',
      key: 'datacaptId',
      ellipsis: true,
      sorter: true,
      fixed: 'left' as ColumnFixedType,
      render: (datacaptId: string, { firstName, lastName }: Partial<Candidate>) =>
        renderContact(firstName, lastName, datacaptId)
    },
    {
      title: columnNames.testId,
      dataIndex: 'testNumber',
      key: 'testNumber',
      sorter: true,
      width: '10rem'
    },
    {
      title: columnNames.creationDate,
      dataIndex: 'creationDate',
      key: 'creationDate',
      sorter: true,
      ellipsis: true,
      render: (creationDate: Date, { creationAuthor }: { creationAuthor: string }) =>
        renderDateWithAuthor(creationDate, creationAuthor)
    },
    {
      title: columnNames.status,
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      ellipsis: true,
      render: renderStatus
    },
    {
      title: columnNames.centerName,
      dataIndex: 'centerName',
      key: 'centerName',
      ellipsis: true,
      sorter: true
    },
    {
      title: columnNames.lastUpdated,
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      sorter: true,
      ellipsis: true,
      render: (lastUpdated: Date, { lastUpdateAuthor }: { lastUpdateAuthor: string }) =>
        renderDateWithAuthor(lastUpdated, lastUpdateAuthor)
    }
  ]
}

export const searchAndFilterOptions = (intl: scopedIntlType, centersOptions: DatacOption[]) => {
  const intlCommon = (id: string) => intl(`common.${id}`)
  const intlTests = (id: string) => intl(`side_by_side.project.tests.${id}`)
  return {
    searchInputPlaceholder: intlCommon('search'),
    filters: [
      {
        label: intlTests('column_name.center'),
        name: 'centers',
        options: centersOptions
      }
    ]
  }
}
