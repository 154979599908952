import { RouteComponentProps, useMatch } from '@reach/router'
import React from 'react'

import { SideBySideProjectBuilder } from '.'
import { ProjectStatus } from '../../../../requests'
import { routes } from '../../../../routes'
import { BuilderElements } from '../../../shared/Builder'
import { useSideBySideProjectDetailsStore } from '../SideBySideProjectDetailsStore'

export const SideBySideProjectBuilderContent: React.FC<RouteComponentProps> = ({ location }) => {
  const match = useMatch('section/:sectionId')
  const { project } = useSideBySideProjectDetailsStore()

  const showInBuilder = () => {
    if (project.status !== ProjectStatus.Draft) {
      return BuilderElements.Placeholder
    }

    if (!match) return BuilderElements.BuilderEntry

    return BuilderElements.Structure
  }

  return (
    !!project?.id && (
      <SideBySideProjectBuilder
        studyId={project.id}
        sectionId={match?.sectionId}
        location={location}
        backRoute={routes.sideBySideProjectSettings(project.id)}
        showInBuilder={showInBuilder()}
        title={project.name}
      />
    )
  )
}
