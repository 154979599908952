import './SideBySideProjectTestZones.less'

import { Button } from 'antd'
import arrayMove from 'array-move'
import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { Product, SideBySideTest, updateSideBySideTest } from '../../../../../requests'
import { DatacIcon, DatacMessage, DatacTitle } from '../../../../common'
import { useSideBySideProjectDetailsStore } from '../../SideBySideProjectDetailsStore'
import { TestZonesProducts, maxProducts } from './TestZonesProducts'
import { TestZonesSelected } from './TestZonesSelected'

interface Props {
  test: SideBySideTest
  setTest: (test: SideBySideTest) => void
}

export const SideBySideProjectTestZones: React.FC<Props> = ({ test, setTest }) => {
  const intl = useScopedIntl('')
  const intlZones = useScopedIntl('side_by_side.project.test.zones')
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([])
  const { project } = useSideBySideProjectDetailsStore()

  useEffect(() => {
    setSelectedProducts(test?.products || [])
  }, [test?.id])

  useEffect(() => {
    if (!test?.id || !project?.id || !selectedProducts?.length) return

    const selectedProductsIds = selectedProducts?.map(p => p.id)
    const testProductsIds = test.products?.map(p => p.id)

    if (
      selectedProductsIds?.length === testProductsIds?.length &&
      selectedProductsIds?.every((id, index) => id === testProductsIds[index])
    ) {
      return
    }

    updateSideBySideTest(
      {
        testId: test.id,
        projectId: project.id,
        products: selectedProductsIds
      },
      {
        onSuccess: newTest => {
          const newProductsCount = newTest.products?.length

          if (test.products?.length !== newProductsCount && newProductsCount === maxProducts) {
            DatacMessage.warning(
              intlZones('maximum_selected.title'),
              intlZones('maximum_selected.description', { max: maxProducts })
            )
          }

          setTest({ ...newTest, isFormStarted: false })
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }, [selectedProducts, test?.id])

  const onAddProduct = (product: Product) => {
    setSelectedProducts(selectedProducts => [...selectedProducts, product])
  }

  const onRemoveProduct = (product: Product) => {
    setSelectedProducts(selectedProducts => selectedProducts.filter(p => p.id !== product.id))
  }

  const onSortProduct = (oldIndex: number, newIndex: number) => {
    setSelectedProducts(arrayMove(selectedProducts, oldIndex, newIndex))
  }

  const onNext = useCallback(() => {
    document.body.scrollTop = 0
    updateSideBySideTest(
      {
        testId: test.id,
        projectId: project.id,
        isFormStarted: true
      },
      {
        onSuccess: setTest,
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }, [test, project])

  return (
    <div className="test-zones">
      <div className="test-zones__header">
        <DatacTitle size="huge">{project.name}</DatacTitle>
        <DatacTitle size="small">{intlZones('title')}</DatacTitle>
        <div className="test-zones__header__description">{intlZones('description', { max: maxProducts })}</div>
      </div>
      <TestZonesProducts onAddProduct={onAddProduct} selectedProducts={selectedProducts} />
      <div className="test-zones__selected-info">
        <DatacTitle size="small">{intlZones('selected')}</DatacTitle>
        <div
          className={classNames(
            'test-zones__selected-info__number',
            !selectedProducts.length && 'test-zones__selected-info__number--error'
          )}
        >
          <DatacIcon raw name={selectedProducts.length ? 'check' : 'x'} />
          {selectedProducts.length}
        </div>
      </div>
      {!!selectedProducts?.length && (
        <>
          <TestZonesSelected
            selectedProducts={selectedProducts}
            onRemoveProduct={onRemoveProduct}
            onSortProduct={onSortProduct}
          />
          <Button role="tab" className="test-zones__next" size="large" type="primary" onClick={onNext}>
            {intl('common.next')}
            <DatacIcon name="chevronRight" size="xbig" type="white-on-semi-transparent" />
          </Button>
        </>
      )}
    </div>
  )
}
