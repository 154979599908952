import { Router } from '@reach/router'
import React from 'react'

import { BasicLayout } from '../../components/BasicLayout'
import { AuthRoute } from '../../components/auth/AuthRoute'
import { SessionTimeoutModal } from '../../components/auth/SessionTimeoutModal'
import { SideBySideProjectDetailsContent, SideBySideProjectsContent } from '../../components/sideBySide'
import { AccountType } from '../../requests'

const IndexPage = () => {
  return (
    <AuthRoute>
      <BasicLayout>
        <SessionTimeoutModal accountType={AccountType.User} />
        <Router basepath="/:lang/side-by-side">
          <SideBySideProjectsContent path="/" />
          <SideBySideProjectDetailsContent path="/:projectId/*" />
        </Router>
      </BasicLayout>
    </AuthRoute>
  )
}

export default IndexPage
export const Head = () => <title>Datacapt - Side-By-Side</title>
